import * as React from "react";
import { StyleSheet, View } from "react-native";

import LongBar from "./LongBar";
import { withAnchorPoint } from "../utils/withAnchorPoint";
import {
  useExceedWidthSideBy,
  useShutterSize,
  useBarHeight,
  useNumberOfBlocks,
  useBarBlocksTranslateX,
} from "./SplashExit.shared";
import { SplashExitProps } from "./SplashExit";

const REVEALING_ROTATION_DEG = 30;

export default function SplashExit(props: SplashExitProps) {
  const exceedWidthSideBy = useExceedWidthSideBy(REVEALING_ROTATION_DEG);
  const shutterSize = useShutterSize(REVEALING_ROTATION_DEG);
  const barHeight = useBarHeight();
  const numberOfBlocks = useNumberOfBlocks(REVEALING_ROTATION_DEG);
  const revealingBarContentTranslateX = useBarBlocksTranslateX(
    REVEALING_ROTATION_DEG
  );

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        // @ts-ignore
        { overflow: "hidden", position: "fixed" },
      ]}
      onLayout={props.onReady}
      pointerEvents="none"
    >
      <View
        style={[
          styles.container,
          { left: -exceedWidthSideBy, right: -exceedWidthSideBy },
        ]}
        pointerEvents="none"
      >
        <View
          style={[
            withAnchorPoint(
              { transform: [{ rotate: "-30deg" }] },
              { x: 0, y: 0.5 },
              {
                width: shutterSize.width,
                height: barHeight,
              }
            ),
          ]}
        >
          <View
            style={{
              transform: [
                {
                  translateX: revealingBarContentTranslateX,
                },
              ],
            }}
          >
            <LongBar
              renderTitle="middle"
              usePaddingAlign={false}
              numberOfBlocks={numberOfBlocks}
              style={{ height: barHeight }}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "stretch",
    overflow: "hidden",
  },
});
