import * as React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  Platform,
} from "react-native";
import Entypo from "@expo/vector-icons/build/Entypo";
import { useHover } from "react-native-web-hooks";

import calculateSize from "../utils/calculateSize";
import * as Colors from "../colors";

const Wrapper = React.forwardRef<
  TouchableOpacity,
  React.PropsWithChildren<TouchableOpacityProps>
>((props, ref) => (
  <TouchableOpacity
    {...props}
    ref={ref}
    style={[styles.wrapper, props.style]}
  />
));

Wrapper.displayName = "HelpButtonWrapper";

interface IconWrapperProps extends TouchableOpacityProps {
  iconName: React.ComponentProps<typeof Entypo>["name"];
}

function HelpButtonWrapperWithIcon({ iconName, ...rest }: IconWrapperProps) {
  const ref = React.useRef<TouchableOpacity>(null);
  const isHovered = useHover(ref);

  const color = isHovered ? Colors.primaryGray : Colors.secondaryGray;

  return (
    <Wrapper ref={ref} {...rest} style={[{ borderColor: color }, rest.style]}>
      <Entypo
        color={color}
        name={iconName}
        style={styles.icon}
        size={calculateSize(2)}
      />
    </Wrapper>
  );
}

export function Open(props: TouchableOpacityProps) {
  return <HelpButtonWrapperWithIcon {...props} iconName="help-with-circle" />;
}

export function Close(props: TouchableOpacityProps) {
  return <HelpButtonWrapperWithIcon {...props} iconName="cross" />;
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: calculateSize(3),
    height: calculateSize(3),
    borderRadius: calculateSize(3),
    borderWidth: calculateSize(0.25),
  },
  icon: Platform.select({
    ios: {
      marginTop: 1,
      marginLeft: 1,
    },
    default: {},
    web: {
      marginTop: 1,
    },
  }),
});
