import Color from "color";
export const backgroundColor = "#FFF5EA"; // maybe #F8F5EE
export const primaryGray = "#7f7c7c";
export const secondaryGray = "#B3AFAF";
export const tertiaryGray = Color(secondaryGray).lighten(0.1).hex();
export const brandRed = "#ff0e0e";
export const brandGreen = "#00B333";
export const brandBlue = "#26ABFF";
export const brandNavy = "#0016FF";
export const brandYellow = "#FFD026";
