import * as React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useWindowDimensions from "../hooks/useWindowDimensions";
import calculateSize from "../utils/calculateSize";

const SPLASH_WIDTH = 2048;
const SPLASH_HEIGHT = 300;

import {
  estimateBlockWidthForLayout,
  estimateBlockHeightForLayout,
  estimateNumberOfBlocksForLayout,
} from "./LongBar";

export function useExceedWidthSideBy(degrees: number) {
  const window = useWindowDimensions();
  const { top } = useSafeAreaInsets();
  return React.useMemo(
    () =>
      (window.height / 2 - (calculateSize(7.3) - 20 + top)) /
      Math.abs(Math.tan((degrees / 180) * Math.PI)),
    [window.height]
  );
}

export function useShutterSize(degrees: number) {
  const window = useWindowDimensions();
  const exceedWidthSideBy = useExceedWidthSideBy(degrees);
  return React.useMemo(
    () => ({
      width: window.width + 2 * exceedWidthSideBy,
      height: window.height / 2,
    }),
    [window, exceedWidthSideBy]
  );
}

export function useBarHeight() {
  const window = useWindowDimensions();
  return React.useMemo(
    () =>
      Math.min(
        (window.width * SPLASH_HEIGHT) / SPLASH_WIDTH,
        calculateSize(7.3) * 0.62
      ),
    [window.width]
  );
}

export function useNumberOfBlocks(degrees: number) {
  const barHeight = useBarHeight();
  const shutterSize = useShutterSize(degrees);
  return React.useMemo(() => {
    const initialEstimate = estimateNumberOfBlocksForLayout({
      width: shutterSize.width,
      height: barHeight,
    });
    // Always return an odd number
    // to have a single block right in the middle.
    if (initialEstimate % 2 === 1) {
      return initialEstimate;
    }

    return initialEstimate + 1;
  }, [shutterSize.width, barHeight]);
}

export function useBarBlocksTranslateX(degrees: number) {
  const barHeight = useBarHeight();
  const shutterSize = useShutterSize(degrees);
  const exceedWidthSideBy = useExceedWidthSideBy(degrees);
  return React.useMemo(() => {
    const barLayout = { height: barHeight };
    const offsetFromInitialToZero =
      -shutterSize.width / 2 + // block middle to zero
      estimateBlockWidthForLayout(barLayout) / 2 - // block middle to block middle (block 0 to zero)
      (estimateBlockHeightForLayout(barLayout) / 2) * Math.tan(Math.PI / 3); // offset white space
    const offsetFromZeroToEdgeOfScreen =
      exceedWidthSideBy / Math.cos(Math.PI / 6);
    const offsetFromEdgeOfScreenToLayout =
      calculateSize(1) / Math.cos(Math.PI / 6);
    const magicOffset = 14;
    return (
      offsetFromInitialToZero +
      offsetFromZeroToEdgeOfScreen +
      offsetFromEdgeOfScreenToLayout +
      magicOffset
    );
  }, [barHeight, shutterSize.width, exceedWidthSideBy]);
}

export const REVEALING_ROTATION_DEG = 30;
