import * as React from "react";
import {
  View,
  StyleSheet,
  ViewProps,
  LayoutChangeEvent,
  LayoutRectangle,
} from "react-native";

import * as Colors from "../colors";
import { LongBarBlock } from "./LongBarBlock";

export interface LongBarProps extends ViewProps {
  numberOfBlocks?: number;
  usePaddingAlign?: boolean;
  renderTitle?: "middle" | "start";
}

export function estimateBlockHeightForLayout({ height }: { height: number }) {
  return (11.9 * height) / 32;
}

export function estimateBlockWidthForLayout({ height }: { height: number }) {
  return (54 * height) / 32;
}

export function estimateDistanceBetweenBlocksForLayout({
  height,
}: {
  height: number;
}) {
  return (18.6 * height) / 32;
}

export function estimateNumberOfBlocksForLayout({
  width,
  height,
}: {
  width: number;
  height: number;
}): number {
  const distanceBetweenBlocks = estimateDistanceBetweenBlocksForLayout({
    height,
  });
  const blockWidth = estimateBlockWidthForLayout({ height });
  const estimatedBlocksCount = Math.floor(
    width / (blockWidth + distanceBetweenBlocks)
  );
  return estimatedBlocksCount;
}

export default function LongBar({
  numberOfBlocks,
  children,
  renderTitle,
  usePaddingAlign,
  ...props
}: React.PropsWithChildren<LongBarProps>) {
  const [estimatedBlocksCount, setEstimatedBlocksCount] = React.useState(0);
  const [layout, setLayout] = React.useState<LayoutRectangle | null>(null);
  const onLayout = React.useCallback(
    (event: LayoutChangeEvent) => {
      setLayout(event.nativeEvent.layout);
      setEstimatedBlocksCount(
        estimateNumberOfBlocksForLayout(event.nativeEvent.layout)
      );
      props.onLayout?.(event);
    },
    [setEstimatedBlocksCount, props.onLayout]
  );
  const blocksCount = numberOfBlocks ?? estimatedBlocksCount;
  const blocks = React.useMemo(() => {
    const renderTitleIfOdd =
      renderTitle === "middle" ? Math.floor(blocksCount / 2) % 2 : false;
    return Array.from(Array(blocksCount).keys()).map((key) => (
      <LongBarBlock
        key={`${key}`}
        style={styles.block}
        renderTitle={key % 2 === Number(renderTitleIfOdd)}
      />
    ));
  }, [blocksCount, renderTitle]);

  const paddingHorizontal = layout
    ? (layout.width -
        blocksCount * estimateBlockWidthForLayout(layout) -
        blocksCount * estimateDistanceBetweenBlocksForLayout(layout)) /
      2
    : 0;

  return (
    <View
      {...props}
      onLayout={onLayout}
      style={[styles.background, props.style]}
    >
      <View style={styles.backgroundPadding} />
      <View style={styles.container}>
        <View style={styles.containerPadding} />
        <View
          style={[
            styles.content,
            !children && (usePaddingAlign ?? true) && { paddingHorizontal },
          ]}
        >
          {children ?? blocks}
        </View>
        <View style={styles.containerPadding} />
      </View>
      <View style={styles.backgroundPadding} />
    </View>
  );
}

const styles = StyleSheet.create({
  background: {
    height: 30,
    backgroundColor: Colors.brandRed,
  },
  backgroundPadding: {
    flex: 28,
  },
  container: {
    flex: 244,
    backgroundColor: "#fff",
  },
  containerPadding: {
    flex: 66,
  },
  content: {
    flex: 112,
    justifyContent: "space-around",
    flexDirection: "row",
  },
  block: {
    flex: 1,
  },
});
