import * as React from "react";
import {
  StyleSheet,
  Text as RNText,
  TextProps as RNTextProps,
  ViewProps,
  View,
} from "react-native";
import {
  H1 as EH1,
  H3 as EH3,
  P as EP,
  Strong as EStrong,
} from "@expo/html-elements";
import calculateSize from "../utils/calculateSize";
import { brandNavy } from "../colors";
import useFont from "../utils/useFont";

type TextProps = React.PropsWithChildren<RNTextProps>;

export function Text(props: TextProps) {
  return <RNText {...props} style={[styles.base, props.style]} />;
}

export function Strong(props: TextProps) {
  return <EStrong {...props} style={[styles.base, styles.strong, props.style]} />;
}

export function H1(props: TextProps) {
  return <EH1 {...props} style={[styles.base, styles.h1, props.style]} />;
}

export function H3(props: TextProps) {
  return <EH3 {...props} style={[styles.base, styles.h3, props.style]} />;
}

export function P(props: TextProps) {
  return <EP {...props} style={[styles.base, styles.p, props.style]} />;
}

export function Code(props: TextProps) {
  return <Text {...props} style={[styles.code, props.style]} />;
}

export function ButtonText(props: TextProps) {
  return <Text {...props} style={[styles.button, props.style]} />;
}

export function TouchableText(props: TextProps) {
  return <Text {...props} style={[styles.touchable, props.style]} />;
}

const OlContext = React.createContext<() => number>(() => 0);
export function Ol(props: React.PropsWithChildren<ViewProps>) {
  const currentIndex = React.useRef(1);
  React.useLayoutEffect(() => {
    currentIndex.current = 1;
  }, []);
  const getIndex = React.useCallback(() => {
    const index = currentIndex.current;
    currentIndex.current += 1;
    return index;
  }, [currentIndex]);
  return (
    <OlContext.Provider value={getIndex}>
      <View {...props} style={[styles.ol, props.style]} />
    </OlContext.Provider>
  );
}

export function Li(props: React.PropsWithChildren<ViewProps>) {
  const getIndex = React.useContext(OlContext);
  return (
    <View {...props} style={[styles.li, props.style]}>
      <Text style={styles.liIndicator}>{getIndex()}. </Text>
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  base: {
    fontSize: calculateSize(1),
    ...useFont("Inter_400Regular"),
    // fontFamily: "Inter_400Regular",
  },
  h1: {
    fontSize: calculateSize(2),
    ...useFont("Inter_900Black"),
    // textShadowColor: "rgba(0, 0, 0, 0.5)",
    // textShadowOffset: { height: StyleSheet.hairlineWidth, width: 0 },
    // textShadowRadius: StyleSheet.hairlineWidth
  },
  h3: {
    fontSize: calculateSize(1.5),
    ...useFont("Inter_600SemiBold"),
  },
  strong: {
    ...useFont("Inter_600SemiBold"),
  },
  code: {
    ...useFont("OverpassMono_400Regular"),
  },
  p: {
    lineHeight: calculateSize(1.5),
  },
  button: {
    fontSize: calculateSize(1.2),
    ...useFont("Inter_600SemiBold"),
  },
  touchable: {
    color: brandNavy,
    ...useFont("Inter_600SemiBold"),
  },
  ol: {
    marginTop: calculateSize(1),
  },
  li: {
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: calculateSize(1),
  },
  liIndicator: {
    marginRight: calculateSize(1),
  },
});
