import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import loadable, { LoadableComponent } from "@loadable/component";
import WelcomeScreen from "./screens/WelcomeScreen";
import KeyFobScreen from "./screens/KeyFobScreen";
import LoginInputScreen from "./screens/LoginInputScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";

const HelpScreen = loadable(() => import("./screens/HelpScreen"));
const CodeInputScreen = loadable(() => import("./screens/CodeInputScreen"));

import { UserContext } from "./contexts/UserContext";
import { ActivityIndicator } from "react-native";

export type RootStackParamList = {
  Welcome: undefined;
  KeyFob: undefined;
  Help:
    | undefined
    | {
        headerIsBeneathLogo?: boolean;
      };
  SignIn: undefined;
  LoginInput: undefined;
  CodeInput: {
    login: string;
    verificationId: string;
  };
  PrivacyPolicy: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();

function NullHeader() {
  return null;
}

function WrapWithSuspense<T>(Component: LoadableComponent<T>) {
  return (props: T) => (
    <React.Suspense fallback={<ActivityIndicator />}>
      <Component {...props} />
    </React.Suspense>
  );
}

const HelpScreenWithSuspense = WrapWithSuspense(HelpScreen);
const CodeInputScreenWithSuspense = WrapWithSuspense(CodeInputScreen);

export default function Navigator() {
  const user = React.useContext(UserContext);
  React.useEffect(() => {
    HelpScreen.preload();
    CodeInputScreen.preload();
  }, []);

  return (
    <Stack.Navigator headerMode="screen" screenOptions={{ header: NullHeader }}>
      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{ title: "Otwórz się" }}
      />
      <Stack.Screen
        name="KeyFob"
        component={KeyFobScreen}
        options={{ title: "Otwórz się" }}
      />
      {!user ? (
        <>
          <Stack.Screen
            name="LoginInput"
            component={LoginInputScreen}
            options={{ title: "Logowanie" }}
          />
          <Stack.Screen
            name="CodeInput"
            component={CodeInputScreenWithSuspense}
            options={{ title: "Weryfikacja" }}
          />
        </>
      ) : (
        <></>
      )}
      <Stack.Screen
        name="Help"
        component={HelpScreenWithSuspense}
        options={{ title: "Pomocy" }}
      />
      <Stack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicyScreen}
        options={{ title: "Polityka prywatności" }}
      />
    </Stack.Navigator>
  );
}
