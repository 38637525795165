import Plausible from "plausible-tracker";

const plausible = Plausible({
  domain: "sezamie.pl",
  trackLocalhost: true,
  apiHost: "https://stats.staszek.asia",
});

plausible.enableAutoPageviews();

export default function useAnalytics() {
  return plausible;
}
