import * as React from "react";
import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps,
} from "react-native";
import calculateSize from "../utils/calculateSize";
import useFont from "../utils/useFont";

export function TextInput(props: TextInputProps) {
  return <RNTextInput {...props} style={[styles.base, props.style]} />;
}

const styles = StyleSheet.create({
  base: {
    ...useFont("Inter_400Regular"),
    paddingHorizontal: calculateSize(1),
    paddingVertical: calculateSize(1),
    backgroundColor: "white",
    borderRadius: calculateSize(1),
    borderColor: "black",
    borderWidth: 3,
    fontSize: calculateSize(1.1),
    margin: 0,
  },
});
