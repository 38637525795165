import * as React from "react";
import * as SplashScreen from "expo-splash-screen";

export default function useSplashScreen(visible: boolean) {
  React.useLayoutEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);
  React.useEffect(() => {
    if (!visible) {
      SplashScreen.hideAsync();
    }
  }, [visible]);
}
