import * as React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  NavigationContainer,
  getStateFromPath,
  getPathFromState,
  NavigationState,
} from "@react-navigation/native";
import { AppearanceProvider } from "react-native-appearance";
import { enableScreens } from "react-native-screens";
import { Main } from "@expo/html-elements";
import Constants from "expo-constants";

import * as Colors from "./src/colors";
import SettingsContextProvider from "./src/contexts/SettingsContext";
import StatusBarUnderlay from "./src/components/StatusBarUnderlay";
import SplashExit from "./src/components/SplashExit";
import useApplicationFonts from "./src/hooks/useApplicationFonts";
import UserContextProvider from "./src/contexts/UserContext";
import Navigator from "./src/Navigator";
import useAwaiter from "./src/hooks/useAwaiter";
import useSplashScreen from "./src/hooks/useSplashScreen";
import useInitializeFirebase from "./src/hooks/useInitializeFirebase";
import { setStatusBarStyle } from "expo-status-bar";

enableScreens();

export default function App() {
  const [fontsLoaded] = useApplicationFonts();
  const firebaseHasLoaded = useInitializeFirebase();
  const [userHasLoaded, finishLoadingUser] = useAwaiter();
  const [splashExitIsReady, finishLoadingSplashExit] = useAwaiter();
  const [settingsAreLoaded, finishLoadingSettings] = useAwaiter();

  const isLoaded =
    firebaseHasLoaded &&
    fontsLoaded &&
    userHasLoaded &&
    splashExitIsReady &&
    settingsAreLoaded;

  useSplashScreen(!(splashExitIsReady && settingsAreLoaded));

  const onStateChange = React.useMemo(
    () =>
      Platform.select({
        default: () => {
          setStatusBarStyle("dark");
        },
        ios: (state: NavigationState | undefined) => {
          if (state && state?.routes.length > 1) {
            setStatusBarStyle("light");
          } else {
            setStatusBarStyle("dark");
          }
        },
      }),
    []
  );

  return (
    <SafeAreaProvider>
      <AppearanceProvider>
        <SettingsContextProvider onReady={finishLoadingSettings}>
          <NavigationContainer
            onStateChange={onStateChange}
            linking={{
              prefixes: ["https://www.sezamie.pl/", "sezamie://"],
              config: {
                screens: {
                  Welcome: "/",
                  KeyFob: "/keyfob",
                  LoginInput: "/sign-in",
                  CodeInput: "/verify",
                  Help: "/help",
                  PrivacyPolicy: "/privacy",
                },
              },
              getPathFromState: (state, config) => {
                if (!state) {
                  return "/";
                }
                return getPathFromState(state, config);
              },
              getStateFromPath: (path, config) => {
                const state = getStateFromPath(path, config);
                if (
                  state &&
                  ["PrivacyPolicy", "Help"].includes(state.routes[0].name)
                ) {
                  return {
                    routes: [{ name: "KeyFob" }, ...state.routes],
                  };
                } else if (state && state.routes[0].name === "CodeInput") {
                  return getStateFromPath("/");
                }
                return state;
              },
            }}
            documentTitle={{
              formatter: (options, route) => {
                const title = options?.title ?? route?.name;
                if (title) {
                  return `${title} ␥ Sezamie`;
                }
                return `␥ Sezamie ␥`;
              },
            }}
            theme={{
              colors: {
                background: Colors.backgroundColor,
                card: "#111D4A",
                border: "#92140C",
                primary: "#FFCF99",
                text: "#1E1E24",
                notification: "#1E1E24",
              },
              dark: false,
            }}
          >
            {(settingsAreLoaded || Platform.OS === "web") && (
              <Main style={styles.container}>
                {Platform.select({
                  web: firebaseHasLoaded,
                  default: firebaseHasLoaded && fontsLoaded,
                }) && (
                  <UserContextProvider
                    onReady={finishLoadingUser}
                    disabled={!firebaseHasLoaded}
                  >
                    {userHasLoaded ? (
                      <Navigator />
                    ) : null}
                  </UserContextProvider>
                )}
              </Main>
            )}
          </NavigationContainer>
          <SplashExit
            shouldRevealContentBeneath={isLoaded}
            onReady={finishLoadingSplashExit}
          />
          <StatusBarUnderlay isHidden={!isLoaded} />
        </SettingsContextProvider>
      </AppearanceProvider>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.backgroundColor,
    // alignItems: "center",
    flex: 1,
  },
  statusBarUnderlay: {
    height: Constants.statusBarHeight,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
});
