import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useInView } from "react-intersection-observer";
const Entypo = React.lazy(() => import("@expo/vector-icons/build/Entypo"));

import { TouchableText, ButtonText } from "../components/Text";
import * as Colors from "../colors";
import Button from "../components/Button";
import calculateSize from "../utils/calculateSize";
import useAnalytics from "../hooks/useAnalytics";

enum State {
  LOADING,
  PROMPTING,
  WAITING,
  OPENING,
  FINISHED,
}

export default function VideoDemo() {
  const analytics = useAnalytics();
  const videoRef = React.useRef<HTMLVideoElement>();
  const [interactionObserverRef, isVideoInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const setVideoRef = React.useCallback(
    (ref) => {
      videoRef.current = ref;
      interactionObserverRef(ref);
    },
    [videoRef, interactionObserverRef]
  );

  React.useEffect(() => {
    if (isVideoInView) {
      videoRef.current?.play();
    }

    analytics.trackEvent("Demo video in view");
  }, [isVideoInView]);

  const [hasPaused, setHasPaused] = React.useState(false);
  const [hasFinished, setHasFinished] = React.useState(false);
  const [openIsEnabled, setOpenIsEnabled] = React.useState(false);

  const onTimeUpdate = React.useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    (event) => {
      if (event.currentTarget.currentTime > 1.5) {
        setOpenIsEnabled(true);
      }
      if (!hasPaused && event.currentTarget.currentTime > 6) {
        event.currentTarget.pause();
        setHasPaused(true);
      }
    },
    [videoRef, hasPaused, setHasPaused, setOpenIsEnabled]
  );

  const onOpen = React.useCallback(() => {
    const videoElement = videoRef.current;
    analytics.trackEvent("Demo gate opened");
    if (videoElement) {
      setHasPaused(true);
      videoElement.play();
    }
  }, [videoRef, setHasPaused]);

  return (
    <View style={styles.container}>
      <TouchableText style={styles.demoText}>
        Kiedy tylko potrzebujesz…
      </TouchableText>
      <video
        src="/demo-video.mp4"
        ref={setVideoRef}
        style={{ objectFit: "contain", maxHeight: "40vh" }}
        onEnded={() => setHasFinished(true)}
        onTimeUpdate={onTimeUpdate}
      />
      <TouchableText style={styles.demoText}>
        naciskasz przycisk 👇…
      </TouchableText>
      <Button
        onPress={onOpen}
        backgroundColor="#fff"
        style={styles.openButton}
        disabled={!openIsEnabled}
        borderColor={Colors.brandRed}
      >
        <ButtonText>
          <React.Suspense fallback={null}>
            <Entypo name="login" size={18} style={styles.buttonIcon} />
          </React.Suspense>{" "}
          Otwórz
        </ButtonText>
      </Button>
      <TouchableText style={styles.demoText}>
        a brama się otwiera!
      </TouchableText>
      <div
        style={{
          pointerEvents: hasFinished ? "auto" : "none",
          transition: "ease-in-out",
          transitionProperty: "opacity",
          transitionDuration: "0.5s",
          opacity: Number(hasFinished),
        }}
      >
        <View style={styles.overlay}>
          <Button
            backgroundColor="#fff"
            style={styles.repeatButton}
            borderColor={Colors.brandRed}
            onPress={() => {
              setHasPaused(false);
              setHasFinished(false);
              setOpenIsEnabled(false);
              analytics.trackEvent("Demo reset");
              const videoElement = videoRef.current;
              if (videoElement) {
                videoElement.currentTime = 0;
                videoElement.play();
              }
            }}
          >
            <ButtonText>
              <React.Suspense fallback={null}>
                <Entypo name="ccw" size={18} style={styles.buttonIcon} />
              </React.Suspense>{" "}
              Jeszcze raz
            </ButtonText>
          </Button>
        </View>
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: calculateSize(0.5),
    borderRadius: calculateSize(1),
  },
  demoText: {
    textAlign: "center",
    color: Colors.secondaryGray,
    marginBottom: calculateSize(1),
  },
  openButton: {
    alignSelf: "center",
    marginBottom: calculateSize(1),
  },
  buttonIcon: {
    marginRight: calculateSize(0.25),
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    margin: -calculateSize(0.5),
    borderRadius: calculateSize(1),
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  repeatButton: {
    shadowColor: "#000",
    shadowOpacity: 1,
    shadowRadius: calculateSize(5),
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
});
