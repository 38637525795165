import { useState, useEffect } from "react";

type parsePhoneNumberFromString = typeof import("libphonenumber-js/mobile").parsePhoneNumberFromString;

export default function useParsePhoneNumberFromString(): null | parsePhoneNumberFromString {
  const [
    parsePhoneNumberFromString,
    setParsePhoneNumberFromString,
  ] = useState<null | parsePhoneNumberFromString>(null);
  useEffect(() => {
    (async () => {
      const { parsePhoneNumberFromString } = await import(
        "libphonenumber-js/mobile"
      );
      setParsePhoneNumberFromString(() => parsePhoneNumberFromString);
    })();
  }, []);
  return parsePhoneNumberFromString;
}
