import * as React from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Color from "color";

export interface ButtonProps {
  children: React.ReactNode;
  backgroundColor?: string;
  onPress: () => void;
  borderColor?: string;
  paddingWidth?: number;
  borderWidth?: number;
  disabled?: boolean;
  style?: ViewStyle;
}

const DEFAULT_BORDER_WIDTH = 4;

export default function Button({
  onPress,
  children,
  borderColor: enabledBorderColor,
  backgroundColor: enabledBackgroundColor,
  paddingWidth,
  borderWidth,
  disabled,
  style,
}: ButtonProps) {
  const finalBorderWidth = borderWidth ?? DEFAULT_BORDER_WIDTH;
  const backgroundColor = disabled
    ? Color(enabledBackgroundColor).grayscale().hex()
    : enabledBackgroundColor;
  const borderColor = disabled
    ? Color(enabledBorderColor).grayscale().hex()
    : enabledBorderColor;
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.container,
        disabled ? { opacity: 0.5 } : null,
        backgroundColor ? { backgroundColor } : null,
        paddingWidth !== undefined ? { padding: paddingWidth } : null,
        style,
      ]}
    >
      <View
        style={[
          styles.innerContainer,
          borderColor ? { borderColor, borderWidth: finalBorderWidth } : null,
        ]}
      >
        {children}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 9999,
    backgroundColor: "white",
    padding: 3,
  },
  innerContainer: {
    borderRadius: 9999,
    paddingVertical: 9,
    paddingHorizontal: 15,
  },
});
