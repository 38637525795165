import * as React from "react";
import loadable from "@loadable/component";
import { View, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { useFocusEffect } from "@react-navigation/native";
import firebase from "firebase/app";

import { ButtonText } from "../components/Text";
import * as Colors from "../colors";
const HelpButtonOpen = loadable(() => import("../components/HelpButton"), {
  resolveComponent: ({ Open }) => Open,
});
import { RootStackParamList } from "../Navigator";
import Header from "../components/NavigationHeader";
import calculateSize from "../utils/calculateSize";
import Container from "../components/Container";
import Gate from "../components/Gate";
import { UserContext } from "../contexts/UserContext";

export interface KeyFobScreenProps
  extends StackScreenProps<RootStackParamList, "KeyFob"> {}

export default function KeyFobScreen(props: KeyFobScreenProps) {
  const [gates, setGates] = React.useState<
    firebase.firestore.DocumentSnapshot[] | undefined
  >(undefined);
  const user = React.useContext(UserContext);
  React.useEffect(() => {
    if (!user) {
      return;
    }

    try {
      return firebase
        .app()
        .firestore()
        .collection(`users/${user.uid}/userGates`)
        .onSnapshot(
          (snapshot) => {
            setGates(snapshot.docs);
          },
          (error) => console.warn(error)
        );
    } catch (error) {
      console.warn(error);
    }
  }, [user]);

  useFocusEffect(
    React.useCallback(() => {
      if (user === null) {
        props.navigation.replace("LoginInput");
      }
    }, [props.navigation, user])
  );

  return (
    <Container>
      <Header
        title="Otwórz się"
        headerRight={() => (
          <React.Suspense fallback={null}>
            <HelpButtonOpen onPress={() => props.navigation.navigate("Help")} />
          </React.Suspense>
        )}
      />
      <View style={styles.gatesContainer}>
        {gates?.map((gate) => (
          <Gate key={gate.id} doc={gate} style={styles.gate} />
        ))}
        {(gates?.length ?? 0) % 2 === 1 ? <View style={styles.gate} /> : null}
      </View>
      <ButtonText style={styles.footerText}>Jedź bezpiecznie!</ButtonText>
    </Container>
  );
}

const styles = StyleSheet.create({
  gatesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -8,
    justifyContent: "center",
  },
  gate: {
    flex: 1,
    flexBasis: calculateSize(20),
    margin: 8,
  },
  footerText: {
    color: Colors.secondaryGray,
    fontSize: 14,
    textAlign: "center",
    margin: calculateSize(1),
  },
});
