import * as React from "react";
import { View, StyleSheet } from "react-native";
import firebase from "firebase/app";

import Button from "../components/Button";
import { ButtonText, P } from "../components/Text";
import { TextInput } from "../components/TextInput";
import * as Colors from "../colors";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../Navigator";
import calculateSize from "../utils/calculateSize";
import Header from "../components/NavigationHeader";
import Container from "../components/Container";
import FirebaseRecaptcha from "../components/FirebaseRecaptcha";
import useParsePhoneNumberFromString from "../hooks/useParsePhoneNumberFromString";

export interface LoginInputScreenProps
  extends StackScreenProps<RootStackParamList, "LoginInput"> {}

export default function LoginInputScreen(props: LoginInputScreenProps) {
  const [login, setLogin] = React.useState("");
  const [
    verifier,
    setVerifier,
  ] = React.useState<null | firebase.auth.ApplicationVerifier>(null);
  const [isVerifyingLogin, setIsVerifyingLogin] = React.useState(false);
  const parsePhoneNumberFromString = useParsePhoneNumberFromString();
  const parsedNumber = parsePhoneNumberFromString?.(login, "PL");
  const isValidNumber = parsedNumber?.isValid();
  const submit = React.useCallback(async () => {
    try {
      if (!verifier) {
        throw new Error("Unexpected error, app not ready");
      }
      setIsVerifyingLogin(true);
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const { verificationId } = await firebase
        .auth()
        .signInWithPhoneNumber(`${parsedNumber!.number}`, verifier);
      props.navigation.navigate("CodeInput", {
        login: parsedNumber!.formatInternational({ v2: true }),
        verificationId,
      });
    } catch (error) {
      alert(error);
    } finally {
      setIsVerifyingLogin(false);
    }
  }, [setIsVerifyingLogin, parsedNumber, props.navigation, verifier]);
  return (
    <Container>
      <Header title="Logowanie" />
      <P style={styles.introduction}>
        Aby zalogować się, podaj swój numer telefonu. Będzie on Twoim loginem
        do aplikacji.
      </P>
      <TextInput
        textContentType="telephoneNumber"
        placeholder="Numer telefonu – Twój login"
        style={styles.textInput}
        value={login}
        editable={!isVerifyingLogin}
        onChangeText={setLogin}
        onSubmitEditing={submit}
      />
      <Button
        disabled={!isValidNumber || isVerifyingLogin}
        borderColor="white"
        backgroundColor={Colors.brandRed}
        onPress={submit}
        style={{ alignSelf: "flex-end" }}
      >
        <View style={styles.buttonWrapper}>
          <ButtonText>Dalej</ButtonText>
        </View>
      </Button>
      <FirebaseRecaptcha onVerifierReady={setVerifier} />
    </Container>
  );
}

const styles = StyleSheet.create({
  introduction: {
    marginBottom: calculateSize(1),
  },
  textInput: {
    marginBottom: calculateSize(0.618),
  },
  buttonWrapper: {
    backgroundColor: "white",
    alignSelf: "stretch",
    marginHorizontal: -12,
    marginVertical: -6,
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
