import * as React from "react";
import { View, TextProps, ViewProps } from "react-native";
import { Header as EHeader } from "@expo/html-elements";

import * as HelpButton from "../components/HelpButton";
import { StyleSheet } from "react-native";
import calculateSize from "../utils/calculateSize";
import { H1 } from "../components/Text";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../Navigator";

export interface HeaderProps extends ViewProps {
  title?: string;
  collapsed?: boolean;
  headerTitle?: (props: TextProps) => JSX.Element;
  headerLeft?: () => JSX.Element;
  headerRight?: () => JSX.Element;
}

export default function Header({
  title,
  headerTitle,
  headerLeft,
  headerRight: providedHeaderRight,
  collapsed,
  ...rest
}: HeaderProps) {
  const renderedTitle = React.useMemo(() => {
    if (typeof headerTitle === "string" || typeof title === "string") {
      return <H1 style={styles.title}>{headerTitle ?? title}</H1>;
    }
    if (typeof headerTitle === "function") {
      return headerTitle({
        style: styles.title,
      });
    }
    return null;
  }, [headerTitle, title]);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const headerRight = React.useCallback(() => {
    if (providedHeaderRight) {
      return providedHeaderRight();
    }

    if (!navigation.canGoBack()) {
      return null;
    }

    return <HelpButton.Close onPress={() => navigation.goBack()} />;
  }, [providedHeaderRight, navigation]);
  return (
    <EHeader {...rest}>
      <View style={[styles.imageRow, collapsed && styles.collapsedImageRow]}>
        <View>{headerLeft?.()}</View>
        <View>{headerRight()}</View>
      </View>
      {renderedTitle}
    </EHeader>
  );
}
const styles = StyleSheet.create({
  imageRow: {
    height: calculateSize(7.3),
    alignSelf: "stretch",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: calculateSize(1),
  },
  collapsedImageRow: {
    height: "auto",
  },
  title: {
    fontSize: 48,
  },
});
