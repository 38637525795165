import * as React from "react";
import { useAsyncStorage } from "@react-native-community/async-storage";

export type AppSettings = {
  disableAnimatedSplashExit?: boolean;
};

const STORAGE_KEY = "sezam:settings";

const initialContextState: SettingsContextType = [null, () => {}];
type SettingsContextType = [
  AppSettings | null,
  (newSettings: Partial<AppSettings>) => void
];

export const SettingsContext = React.createContext<SettingsContextType>(
  initialContextState
);

export default function SettingsContextProvider({
  children,
  onReady,
}: React.PropsWithChildren<{ onReady?: () => void }>) {
  const [settings, setSettings] = React.useState<AppSettings | null>(null);
  const AsyncStorage = useAsyncStorage(STORAGE_KEY);

  // Initial value load
  React.useEffect(() => {
    AsyncStorage.getItem().then((settingsJSON) => {
      setSettings(JSON.parse(settingsJSON ?? "{}"));
    }).catch(console.warn);
  }, [setSettings]);

  // onReady
  React.useEffect(() => {
    if (settings) {
      onReady?.();
    }
  }, [settings, onReady]);

  // Auto-save
  React.useEffect(() => {
    if (settings) {
      AsyncStorage.setItem(JSON.stringify(settings));
    }
  }, [settings]);

  const updateSettings = React.useCallback(
    (newSettings: Partial<AppSettings>) => {
      setSettings({ ...settings, ...newSettings });
    },
    [setSettings]
  );

  return (
    <SettingsContext.Provider value={[settings, updateSettings]}>
      {children}
    </SettingsContext.Provider>
  );
}
