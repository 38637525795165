import * as React from "react";
import firebase from "firebase/app";

export default function useFirebaseDocData(
  doc: firebase.firestore.DocumentSnapshot
) {
  const [data, setData] = React.useState(doc.data());
  const [error, setError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    try {
      return doc.ref.onSnapshot((snapshot) => {
        setData(snapshot.data());
        setError(null);
      });
    } catch (error) {
      setData(undefined);
      setError(error);
    }
  }, [doc, setData, setError]);
  return [data, error];
}
