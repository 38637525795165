import { Platform, TextStyle } from "react-native";

export default function useFont(fontName: string) {
  const [fontFamily, style] = fontName.split("_");
  const fontWeight = `${Number.parseInt(style)}` as TextStyle["fontWeight"];

  return {
    fontFamily: Platform.select({
      web: fontFamily.replace(/([A-Z])/g, " $1"),
      default: fontName,
    }),
    fontWeight,
  };
}
