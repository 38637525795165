import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  ScrollViewProps,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import calculateSize from "../utils/calculateSize";

export default function Container(
  props: React.PropsWithChildren<ScrollViewProps>
) {
  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.select({
        ios: "height",
        android: "padding",
      })}
    >
      <SafeAreaView style={styles.content}>{props.children}</SafeAreaView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "stretch",
    paddingHorizontal: calculateSize(1),
    justifyContent: "center",
    flexDirection: "row",
  },
  contentContainer: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  content: {
    flex: 1,
    maxWidth: calculateSize(54),
  },
});
