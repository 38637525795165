import * as React from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { TextInput } from "../components/TextInput";
import { P, ButtonText, H1 } from "../components/Text";
import * as Colors from "../colors";
import calculateSize from "../utils/calculateSize";
import Button from "../components/Button";
import { Close } from "../components/HelpButton";
import useAnalytics from "../hooks/useAnalytics";
import useBaseUrl from "../utils/useBaseUrl";

export default function ContactScreen({ onHide }: { onHide: () => void }) {
  const analytics = useAnalytics();
  const baseUrl = useBaseUrl();

  const [location, setLocation] = React.useState("");
  const [contact, setContact] = React.useState("");

  return (
    <View style={styles.container}>
      <H1 style={{ marginVertical: 0 }}>Zgadajmy się!</H1>
      <P style={styles.label}>Co i gdzie jest do otwarcia?</P>
      <TextInput
        value={location}
        onChangeText={setLocation}
        placeholder="Osiedle, ulica, miasto"
        style={styles.input}
      />
      <P style={styles.label}>Jak się skontaktować?</P>
      <TextInput
        value={contact}
        onChangeText={setContact}
        placeholder="Numer tel., e-mail?"
        style={styles.input}
      />
      <Button
        borderColor={Colors.brandRed}
        style={styles.submit}
        onPress={() => {
          analytics.trackEvent("Send for contact button tapped");
          fetch(`${baseUrl}/api/contact`, {
            body: JSON.stringify({
              contact,
              location,
            }),
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
          });
        }}
      >
        <ButtonText>Wyślij</ButtonText>
      </Button>
      <View style={styles.closeButtonContainer}>
        <TouchableOpacity onPress={onHide}>
          <View>
            <Close />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  heading: {
    marginVertical: 0,
  },
  label: {
    marginBottom: calculateSize(0.5),
  },
  input: {
    marginBottom: calculateSize(1),
  },
  submit: {
    alignSelf: "flex-end",
  },
  closeButtonContainer: {
    position: "absolute",
    borderRadius: 1000,
    top: -calculateSize(3),
    right: -calculateSize(2.5),
    backgroundColor: Colors.backgroundColor,
  },
});
