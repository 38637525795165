import * as React from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-modal";

import Header from "../components/NavigationHeader";
import Container from "../components/Container";
import { H3, P, ButtonText, H1, Strong } from "../components/Text";
import * as Colors from "../colors";
import Button from "../components/Button";
import calculateSize from "../utils/calculateSize";
import VideoDemo from "../components/VideoDemo";
import useAnalytics from "../hooks/useAnalytics";
import Color from "color";
import ContactScreen from "./ContactScreen";

export default function WelcomeScreen() {
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);
  const onContactModalOpen = React.useCallback(
    () => setIsContactModalOpen(true),
    [setIsContactModalOpen]
  );
  const onContactModalClose = React.useCallback(
    () => setIsContactModalOpen(false),
    [setIsContactModalOpen]
  );
  return (
    <Container>
      <Header
        title="Sezamie, otwórz się!"
        // headerRight={() => <Text>Halo</Text>}
      />
      <H3>Twój pilot do bramy zawsze pod ręką</H3>
      <P>
        <Strong>Sezamie</Strong> to aplikacja mobilna, którą w łatwy sposób
        otworzysz bramę do swojego osiedla i pozwolisz na to samo swojej
        rodzinie. Koniec z pamiętaniem o pilocie, odbieraniem telefonów
        „otworzysz bramę?”, czy ograniczeniami pamięci starego systemu.
      </P>
      <H3 nativeID="demo">Zobacz jak to działa</H3>
      <VideoDemo />
      <H1>Oferta</H1>
      <TargetGroupBlock
        type="Residents"
        title="👨‍👩‍👧‍👦 Dla mieszkańców"
        callForAction="Skorzystaj już teraz!"
        onContactModalOpen={onContactModalOpen}
      >
        <P>
          Nie dzwoń do spółdzielni, żeby dodać nowy numer telefonu. Załatw to
          w aplikacji!
        </P>
      </TargetGroupBlock>
      <TargetGroupBlock
        title="🧑‍💼 Dla spółdzielni"
        callForAction="Umów prezentację!"
        type="Housing"
        onContactModalOpen={onContactModalOpen}
      >
        <P>
          Banalnie prosta instalacja oraz łatwe zarządzanie numerami telefonów
          przez stronę internetową.
        </P>
      </TargetGroupBlock>
      <TargetGroupBlock
        title="💼 Dla deweloperów i producentów bram"
        callForAction="Zapytaj o wycenę!"
        type="Developers"
        onContactModalOpen={onContactModalOpen}
      >
        <P>
          Nowatorski system bezpieczeństwa osiedli na miarę XXI wieku.
          Wyróżnij się na tle innych!
        </P>
      </TargetGroupBlock>
      <H1>Kontakt</H1>
      <P style={styles.contactParagraph}>
        <Strong>Biuro Obsługi Klienta</Strong>
        <br />
        <a
          href="tel:+48123505170"
          style={{
            textDecoration: "none",
            color: "#000",
          }}
        >
          +48 12 350 51 70
        </a>
        <br />
        <a
          href="mailto:pomocy@sezamie.pl"
          style={{
            textDecoration: "none",
            color: "#000",
          }}
        >
          pomocy@sezamie.pl
        </a>
      </P>
      <Modal
        isOpen={isContactModalOpen}
        style={{
          content: {
            backgroundColor: Colors.backgroundColor,
            borderColor: "#000",
            borderWidth: calculateSize(0.25),
            borderRadius: calculateSize(0.5),
            overflow: "visible",
            position: "static",
            margin: calculateSize(0.5),
            maxWidth: "100%",
            boxSizing: "border-box",
            inset: "0px",
            padding: calculateSize(1),
            paddingTop: calculateSize(1.5),
            paddingBottom: calculateSize(1.5),
          },
          overlay: {
            backgroundColor: Color(Colors.backgroundColor)
              .alpha(0.9)
              .toString(),
            display: "flex",
            flexDirection: "column",
            padding: calculateSize(1.5),
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        appElement={document.getElementById("root")!}
      >
        <ContactScreen onHide={onContactModalClose} />
      </Modal>
    </Container>
  );
}

function TargetGroupBlock({
  title,
  callForAction,
  children,
  type,
  onContactModalOpen,
}: React.PropsWithChildren<{
  title: string;
  callForAction: string;
  type: string;
  onContactModalOpen: () => void;
}>) {
  const analytics = useAnalytics();
  return (
    <View style={styles.contactBlock}>
      <Strong style={styles.contactBlockHeading}>{title}</Strong>
      {children}
      <Button
        borderColor={Colors.brandRed}
        style={{ alignSelf: "center" }}
        onPress={() => {
          onContactModalOpen();
          analytics.trackEvent("Contact button tapped", { props: { type } });
        }}
      >
        <ButtonText>{callForAction}</ButtonText>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  contactParagraph: {
    paddingBottom: calculateSize(2),
  },
  contactBlock: {
    backgroundColor: "white",
    padding: calculateSize(1),
    borderWidth: calculateSize(0.25),
    borderRadius: calculateSize(0.5),
    marginBottom: calculateSize(1),
  },
  contactBlockHeading: {
    fontSize: calculateSize(1.25),
  },
});
