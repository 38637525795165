import useAwaiter from "./useAwaiter";

import * as React from "react";

export default function useInitializeFirebase() {
  const [firebaseHasLoaded, finishLoadingFirebase] = useAwaiter();
  React.useEffect(() => {
    import("../../config/initializeFirebase").then(finishLoadingFirebase);
  }, [finishLoadingFirebase]);
  return firebaseHasLoaded;
}
