import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import Svg, { Path } from "react-native-svg";

import * as Colors from "../colors";

export interface LongBarBlockProps extends ViewProps {
  renderTitle?: boolean;
  backgroundColor?: string;
  textColor?: string;
}

export function LongBarBlock({
  renderTitle,
  backgroundColor,
  textColor,
  ...props
}: LongBarBlockProps) {
  return (
    <View {...props}>
      <svg
        viewBox="0 0 54 12"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        style={{ flex: 1 }}
      >
        <path
          d="M0 11.851L6.074.001 53.39 0l-6.255 11.835L0 11.851z"
          fill={backgroundColor ?? Colors.brandRed}
        />
        {renderTitle && (
          <path
            d="M14.811 5.131h1.313c-.007-.993-.77-1.646-1.993-1.646-1.202 0-2.05.646-2.042 1.605 0 .792.541 1.23 1.437 1.424l.507.104c.563.125.75.264.758.5-.007.243-.23.424-.674.424-.521 0-.834-.25-.855-.716h-1.305c.007 1.271.868 1.84 2.18 1.84 1.286 0 2.05-.555 2.057-1.548-.007-.771-.466-1.313-1.577-1.55l-.417-.09c-.48-.104-.694-.242-.68-.486 0-.222.187-.382.61-.382.445 0 .654.195.681.521zM18.55 8.674c1.048 0 1.73-.5 1.854-1.292h-1.243c-.077.215-.306.333-.584.333-.402 0-.639-.264-.639-.632v-.055h2.466v-.32c0-1.202-.736-1.945-1.882-1.945-1.174 0-1.924.785-1.924 1.959 0 1.222.736 1.952 1.952 1.952zm-.612-2.397c.007-.326.278-.555.612-.555.34 0 .597.229.604.555h-1.216zM20.994 8.604h3.327V7.57h-1.57v-.02l1.515-1.904V4.82h-3.21v1.035h1.598v.02l-1.66 1.98v.75zM26.13 8.667c.493 0 .86-.174 1.09-.598h.035v.535h1.27V6.007c0-.695-.666-1.244-1.75-1.244-1.132 0-1.687.598-1.736 1.285h1.257c.028-.236.202-.347.466-.347.236 0 .403.111.403.306v.013c0 .216-.244.285-.883.334-.778.055-1.389.375-1.389 1.201 0 .758.507 1.112 1.237 1.112zm.444-.861c-.236 0-.396-.112-.396-.327 0-.194.14-.347.452-.396.215-.035.403-.076.541-.139v.32c0 .347-.291.542-.597.542zM29.178 8.604h1.361V6.395c0-.333.174-.534.452-.534.285 0 .465.201.465.534v2.21h1.3v-2.21c0-.333.173-.534.458-.534.277 0 .458.201.458.534v2.21h1.361V6.047c0-.778-.493-1.285-1.222-1.285-.563 0-1.014.306-1.14.771h-.04c-.077-.465-.508-.77-1.036-.77s-.93.298-1.09.77h-.035V4.82h-1.292v3.785zM35.71 8.604h1.361V4.82H35.71v3.785zm.68-4.181c.368 0 .667-.278.667-.625 0-.34-.299-.618-.667-.618-.375 0-.673.277-.673.618 0 .347.298.625.673.625zM39.575 8.674c1.049 0 1.73-.5 1.854-1.292h-1.243c-.076.215-.306.333-.583.333-.403 0-.64-.264-.64-.632v-.055h2.466v-.32c0-1.202-.736-1.945-1.882-1.945-1.174 0-1.924.785-1.924 1.959 0 1.222.736 1.952 1.952 1.952zm-.612-2.397c.007-.326.278-.555.612-.555.34 0 .597.229.604.555h-1.216z"
            fill={textColor ?? "#fff"}
            fillRule="nonzero"
          />
        )}
      </svg>
    </View>
  );
}

const styles = StyleSheet.create({
  block: {
    flex: 1,
  },
});
