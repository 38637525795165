import * as React from "react";
import { View, ViewProps, StyleSheet } from "react-native";

import { Ol, Li as ViewLi, H3, Strong, P, Code } from "../components/Text";
import calculateSize from "../utils/calculateSize";
import Header from "../components/NavigationHeader";
import Container from "../components/Container";

const styles = StyleSheet.create({
  section: {
    marginVertical: calculateSize(1.5),
  },
});

function Section(props: React.PropsWithChildren<ViewProps>) {
  return <View {...props} style={[styles.section, props.style]} />;
}

function Li(props: React.PropsWithChildren<ViewProps>) {
  return (
    <ViewLi {...props}>
      <P>{props.children}</P>
    </ViewLi>
  );
}

export default function PrivacyPolicyScreen() {
  return (
    <Container>
      <Header title="Polityka prywatności" />
      <Section>
        <H3>Postanowienia ogólne</H3>
        <Ol>
          <Li>
            Administratorem danych osobowych zbieranych za pośrednictwem
            aplikacji
            <Strong>Sezamie</Strong> jest <Strong>Stanisław Chmiela</Strong>{" "}
            wykonujący/a działalność gospodarczą pod firmą{" "}
            <Strong>Stan Stanisław Chmiela</Strong>, adres siedziby:{" "}
            <Strong>Szczodrkowice 166, 32-043 Skała pod Ojcowem</Strong>, adres
            do doręczeń:{" "}
            <Strong>Szczodrkowice 166, 32-043 Skała pod Ojcowem</Strong>, NIP:
            5130262652, REGON: 384512981, wpisaną do Centralnej Ewidencji i
            Informacji o Działalności Gospodarczej, adres poczty elektronicznej:{" "}
            <Code>alibaba@staszek.asia</Code>, dalej „Administrator”, będący
            jednocześnie Usługodawcą.
          </Li>
          <Li>
            Dane osobowe zbierane przez Administratora za pośrednictwem
            aplikacji są przetwarzane zgodnie z Rozporządzeniem Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z
            dnia 10 maja 2018 r.
          </Li>
        </Ol>
      </Section>
      <Section>
        <H3>
          Rodzaj przetwarzanych danych osobowych, cel i zakres zbierania danych
        </H3>
        <Ol>
          <Li>
            <Strong>Cel przetwarzania i podstawa prawna.</Strong> Administrator
            przetwarza dane osobowe za pośrednictwem aplikacji Sezamie w
            przypadku rejestracji użytkownika i korzystania z usług aplikacji.
            Dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO
            jako prawnie usprawiedliwiony interes Administratora.
          </Li>
          <Li>
            <Strong>Rodzaj przetwarzanych danych osobowych.</Strong>{" "}
            Administrator przetwarza numer telefonu użytkownika.
          </Li>
          <Li>
            <Strong>Okres archiwizacji danych osobowych.</Strong>
            Dane osobowe użytkowników przechowywane są przez Administratora:
            <Ol>
              <Li>
                w przypadku, gdy podstawą przetwarzania danych jest wykonanie
                umowy, tak długo, jak jest to niezbędne do wykonania umowy, a po
                tym czasie przez okres odpowiadający okresowi przedawnienia
                roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin
                przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia
                okresowe oraz roszczeń związanych z prowadzeniem działalności
                gospodarczej - trzy lata.
              </Li>
              <Li>
                w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak
                długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody
                przez okres czasu odpowiadający okresowi przedawnienia roszczeń
                jakie może podnosić Administrator i jakie mogą być podnoszone
                wobec niego. Jeżeli przepis szczególny nie stanowi inaczej,
                termin przedawnienia wynosi lat sześć, a dla roszczeń o
                świadczenia okresowe oraz roszczeń związanych z prowadzeniem
                działalności gospodarczej - trzy lata.
              </Li>
            </Ol>
          </Li>
          <Li>
            Podczas korzystania z aplikacji mogą być pobierane dodatkowe
            informacje, w szczególności: adres IP przypisany do urządzenia
            użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa
            domeny, rodzaj urządzenia, czas dostępu, typ systemu operacyjnego.
          </Li>
          <Li>
            Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym
            informacje o linkach i odnośnikach, w które zdecydują się kliknąć
            lub innych czynnościach, podejmowanych w aplikacji. Podstawą prawną
            tego rodzaju czynności jest prawnie uzasadniony interes
            Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu
            korzystania z usług świadczonych drogą elektroniczną oraz na
            poprawie funkcjonalności tych usług.
          </Li>
          <Li>Podanie danych osobowych przez użytkownika jest dobrowolne.</Li>
          <Li>
            Administrator dokłada szczególnej staranności w celu ochrony
            interesów osób, których dane dotyczą, a w szczególności zapewnia, że
            zbierane przez niego dane są:
            <Ol>
              <Li>przetwarzane zgodnie z prawem,</Li>
              <Li>
                zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane
                dalszemu przetwarzaniu niezgodnemu z tymi celami,
              </Li>
              <Li>
                merytorycznie poprawne i adekwatne w stosunku do celów, w jakich
                są przetwarzane oraz przechowywane w postaci umożliwiającej
                identyfikację osób, których dotyczą, nie dłużej niż jest to
                niezbędne do osiągnięcia celu przetwarzania.
              </Li>
            </Ol>
          </Li>
        </Ol>
      </Section>
      <Section>
        <H3>Udostępnienie danych osobowych</H3>
        <P>
          Dane osobowe użytkowników przekazywane są dostawcom usług, z których
          korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy
          usług, którym przekazywane są dane osobowe, w zależności od uzgodnień
          umownych i okoliczności, albo podlegają poleceniom Administratora co
          do celów i sposobów przetwarzania tych danych (podmioty
          przetwarzające) albo samodzielnie określają cele i sposoby ich
          przetwarzania (administratorzy).
        </P>
      </Section>
      <Section>
        <H3>
          Prawo kontroli, dostępu do treści własnych danych oraz ich poprawiania
        </H3>
        <Ol>
          <Li>
            Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych
            osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia
            przetwarzania, prawo do przenoszenia danych, prawo wniesienia
            sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu
            na zgodność z prawem przetwarzania, którego dokonano na podstawie
            zgody przed jej cofnięciem.
          </Li>
          <Li>
            Podstawy prawne żądania użytkownika:
            <Ol>
              <Li>
                <Strong>Dostęp do danych</Strong> – art. 15 RODO
              </Li>
              <Li>
                <Strong>Sprostowanie danych</Strong> – art. 16 RODO
              </Li>
              <Li>
                <Strong>
                  Usunięcie danych (tzw. prawo do bycia zapomnianym)
                </Strong>{" "}
                – art. 17 RODO
              </Li>
              <Li>
                <Strong>Ograniczenie przetwarzania</Strong> – art. 18 RODO
              </Li>
              <Li>
                <Strong>Przeniesienie danych</Strong> – art. 20 RODO
              </Li>
              <Li>
                <Strong>Sprzeciw</Strong> – art. 21 RODO
              </Li>
              <Li>
                <Strong>Cofnięcie zgody</Strong> – art. 7 ust. 3 RODO.
              </Li>
            </Ol>
          </Li>
          <Li>
            W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać
            stosowną wiadomość e-mail na adres:{" "}
            <Code>alibaba@staszek.asia</Code>.
          </Li>
          <Li>
            W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym
            z powyższych praw, Administrator spełnia żądanie albo odmawia jego
            spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po
            jego otrzymaniu. Jeżeli jednak - z uwagi na skomplikowany charakter
            żądania lub liczbę żądań – Administrator nie będzie mógł spełnić
            żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy
            informując użytkownika uprzednio w terminie miesiąca od otrzymania
            żądania - o zamierzonym przedłużeniu terminu oraz jego przyczynach.
          </Li>
          <Li>
            W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza
            przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do
            Prezesa Urzędu Ochrony Danych Osobowych.
          </Li>
        </Ol>
      </Section>
      <Section>
        <H3>Postanowienia końcowe</H3>
        <Ol>
          <Li>
            Administrator stosuje środki techniczne i organizacyjne zapewniające
            ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz
            kategorii danych objętych ochroną, a w szczególności zabezpiecza
            dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem
            przez osobę nieuprawnioną, przetwarzaniem z naruszeniem
            obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub
            zniszczeniem.
          </Li>
          <Li>
            Administrator udostępnia odpowiednie środki techniczne zapobiegające
            pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych
            osobowych przesyłanych drogą elektroniczną.
          </Li>
          <Li>
            W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje
            się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa
            polskiego.
          </Li>
        </Ol>
      </Section>
    </Container>
  );
}
