import * as React from "react";
import firebase from "firebase/app";
import { CallStatus } from "twilio/lib/rest/api/v2010/account/call";

export type GateStatus = CallStatus | "waiting";

export default function useGateStatus(
  gateId: string
): [GateStatus | undefined, Error | null] {
  const [status, setStatus] = React.useState<GateStatus | undefined>(undefined);
  const [error, setError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    try {
      return firebase
        .firestore()
        .doc(`gatesStatuses/${gateId}`)
        .onSnapshot((snapshot) => {
          const { status } = snapshot.data() ?? {};
          setStatus(status);
          setError(null);
        });
    } catch (error) {
      setStatus(undefined);
      setError(error);
    }
  }, [gateId, setStatus, setError]);
  return [status, error];
}
