import * as React from "react";
import Constants from "expo-constants";
import { View, StyleSheet } from "react-native";
import { StatusBarUnderlayProps } from "./StatusBarUnderlay";

export default function StatusBarUnderlay({
  isHidden,
  style,
}: StatusBarUnderlayProps) {
  return (
    <View style={[styles.underlay, style, { opacity: Number(!isHidden) }]} />
  );
}

const styles = StyleSheet.create({
  underlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: Constants.statusBarHeight,
  },
});
