import * as React from "react";
import { View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import firebase from "firebase/app";
import uuidv4 from "uuid/v4";
import { IFirebaseOptions } from "expo-firebase-core";

export interface FirebaseRecaptchaProps {
  firebaseConfig?: IFirebaseOptions;
  onVerifierReady?: (
    verifier: firebase.auth.ApplicationVerifier | null
  ) => void;
}

function FirebaseRecaptcha(props: FirebaseRecaptchaProps) {
  const isFocused = useIsFocused();
  const { current: nativeId } = React.useRef(uuidv4());
  const verifierRef = React.useRef<firebase.auth.RecaptchaVerifier | null>(
    null
  );
  React.useEffect(() => {
    props.onVerifierReady?.(isFocused ? verifierRef.current : null);
  }, [isFocused, verifierRef]);
  const ref = React.useCallback(
    (ref) => {
      if (ref) {
        verifierRef.current = new firebase.auth.RecaptchaVerifier(nativeId, {
          size: "invisible",
        });
        props.onVerifierReady?.(isFocused ? verifierRef.current : null);
      } else {
        verifierRef.current = null;
        props.onVerifierReady?.(isFocused ? verifierRef.current : null);
      }
    },
    [verifierRef, nativeId]
  );
  return isFocused ? <View nativeID={nativeId} ref={ref} /> : null;
}

export default React.memo(FirebaseRecaptcha);
