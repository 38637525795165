import * as React from "react";
import firebase from "firebase/app";

export const UserContext = React.createContext<firebase.User | null | undefined>(null);

export default function UserContextProvider({
  disabled,
  children,
  onReady,
}: React.PropsWithChildren<{ onReady?: () => void; disabled?: boolean }>) {
  const [user, setUser] = React.useState<firebase.User | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (disabled) {
      return;
    }
    if (firebase) {
      return firebase
        .app()
        .auth()
        .onAuthStateChanged((user) => {
          setUser(user);
        });
    }
  }, [firebase, disabled]);

  React.useEffect(() => {
    if (user !== undefined) {
      onReady?.();
    }
  }, [onReady, user]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
