import * as React from "react";
import { StyleSheet, View, ViewProps, Alert } from "react-native";
const Entypo = React.lazy(() => import("@expo/vector-icons/build/Entypo"));
import firebase from "firebase/app";
import { LinearGradient } from "expo-linear-gradient";

import { H1, ButtonText, TouchableText } from "./Text";
import Button from "./Button";
import * as Colors from "../colors";
const GateStatusIndicator = React.lazy(() => import("./GateStatusIndicator"));
import calculateSize from "../utils/calculateSize";
import useBaseUrl from "../utils/useBaseUrl";
import useGateStatus, { GateStatus } from "../hooks/useGateStatus";
import useFirebaseDocData from "../hooks/useFirebaseDocData";
import Color from "color";

export interface GateProps extends ViewProps {
  gateId?: string;
  name?: string;
  doc: firebase.firestore.DocumentSnapshot;
  color?: string;
}

async function openGate(gateId: string) {
  try {
    const token = await firebase.app().auth().currentUser?.getIdToken();
    const response = await fetch(`${useBaseUrl()}/api/gates/${gateId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: "open",
    });
    if (!response.ok) {
      console.warn(await response.text());
    } else {
      console.log(`Call: ${await response.text()}`);
    }
  } catch (error) {
    console.error(error);
    alert(error);
  }
}

function callStatusToProgress(status: GateStatus | "initiating" | undefined) {
  switch (status) {
    case "busy":
    case "canceled":
    case "completed":
    case "no-answer":
      return 100;
    case "in-progress":
      return 70;
    case "queued":
      return 33;
    case "ringing":
      return 45;
    case "initiating":
      return 15;
    case "failed":
    default:
      return 0;
  }
}

export default function Gate({ doc, ...rest }: GateProps) {
  const [data, dataError] = useFirebaseDocData(doc);
  const gateId = doc.data()?.gate.id;
  const [gateStatus, gateStatusError] = useGateStatus(gateId);
  const [isOpening, setIsOpening] = React.useState(false);
  React.useEffect(() => {
    if (
      isOpening &&
      callStatusToProgress(gateStatus) > callStatusToProgress("initiating")
    ) {
      setIsOpening(false);
    }
  }, [isOpening, setIsOpening, gateStatus]);
  const progress = Math.max(
    callStatusToProgress(gateStatus),
    isOpening ? callStatusToProgress("initiating") : 0
  );

  const onChangeName = React.useCallback(() => {
    Alert.prompt(`Zmień nazwę „${data?.name}”`, undefined, [
      {
        style: "cancel",
        text: "Anuluj",
      },
      {
        style: "default",
        text: "Zmień",
        onPress: async (name) => {
          try {
            await doc.ref.update({ name });
          } catch (error) {
            alert(error);
          }
        },
      },
    ]);
  }, [doc]);
  const color = data?.color ?? "#26ABFF";
  return (
    <View
      {...rest}
      style={[
        styles.container,
        rest.style,
        data?.color ? { backgroundColor: data?.color } : null,
      ]}
    >
      <LinearGradient style={StyleSheet.absoluteFill} colors={[Color(color).lighten(0.1).hex(), color, Color(color).darken(0.2).hex()]} />
      <View style={styles.header}>
        <TouchableText style={styles.name} onLongPress={onChangeName}>
          <H1>{data?.name}</H1>
        </TouchableText>
      </View>
      <Button
        disabled={!gateId}
        borderColor={Colors.brandRed}
        style={{ alignSelf: "flex-end" }}
        onPress={async () => {
          setIsOpening(true);
          await openGate(gateId);
        }}
      >
        <ButtonText>
          <React.Suspense fallback={null}>
            <Entypo name="login" size={18} style={styles.openIcon} />
          </React.Suspense>{" "}
          Otwórz
        </ButtonText>
      </Button>
      <React.Suspense fallback={null}>
        <GateStatusIndicator progress={progress} />
      </React.Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    minHeight: calculateSize(10),
    backgroundColor: "#26ABFF",
    borderRadius: calculateSize(0.5),
    padding: calculateSize(1),
    overflow: "hidden",
    // borderColor: "rgba(0,0,4,0.3)",
    // borderWidth: StyleSheet.hairlineWidth,
    // justifyContent: "space-between",
  },
  header: {
    flex: 1,
    flexDirection: "row",
  },
  editButton: {},
  indicator: {
    borderRadius: 8,
  },
  name: {
    flex: 1,
    color: "#fff",
    paddingHorizontal: calculateSize(0.5),
  },
  openIcon: {
    marginRight: calculateSize(0.25),
  },
});
